import React from 'react'
import styles from '../css/styles.module.css';

//Footer of all pages
function Footer() {
    return (
        <div className={styles.footer}>
            <p>eklund.caroline@gmail.com</p>
        </div>
    )
}

export default Footer